<template>
  <c-box w="100%" marginBottom="0">
    <c-flex
      w="100%"
      backgroundColor="white"
      borderRadius="12px"
      border="1px solid #f2f2f2"
      box-shadow="0px 5px 30px 0px #0000000D"
      px="25px"
      py="28px"
      align="center"
    >
      <c-box
        min-w="64px"
        w="64px"
        h="64px"
        marginRight="30px"
        overflow="hidden"
        borderRadius="100%"
        backgroundColor="lightGray.900"
      >
        <c-image
          :src="getPhotoUser(item.photoUrl)"
          :alt="item.firstName"
        />
      </c-box>
      <c-box flexGrow="1">
        <c-flex justify="space-between">
          <c-heading
            as="h3"
            fontSize="18px"
            lineHeight="27px"
            fontWeight="700"
            color="black.900"
            flexGrow="1"
          >
            {{ item.firstName }} {{ item.lastName }}
          </c-heading>
          <c-box
            as="span"
            :color="item.programs.status === 'active' ? 'brand.900' : 'red.900'"
            :borderColor="
              item.programs.status === 'active' ? 'brand.900' : 'red.900'
            "
            borderWidth="1px"
            borderStyle="1px solid"
            borderRadius="30px"
            px="14px"
            py="3px"
            fontSize="12px"
            fontWeight="500"
            display="inline-block"
            verticalAlign="middle"
            marginLeft="8px"
            align-self="flex-start"
            textTransform="capitalize"
          >
            {{ item.programs.status }}
          </c-box>
        </c-flex>
        <c-text
          v-if="item.programs"
          fontFamily="Roboto"
          fontSize="14px"
          lineHeight="21px"
          color="brand.900"
          marginBottom="8px"
        >
          {{ item.programs.name }} ({{ item.programs.programService }})
        </c-text>
      </c-box>
    </c-flex>
  </c-box>
</template>
<script>
import generalMixin from "@/utils/general-mixins";
export default {
  name: "ManagementCardClient",
  props: ["item"],
  mixins: [generalMixin],
};
</script>